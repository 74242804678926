class TextImage {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;

        this.root = el;
        this.moreEl = this.root.querySelector('[data-text-image-more]');
        this.moreColEl = this.root.querySelector('[data-text-image-more-col]');

        return true;
    }

    init() {
        this.setMore();
    }

    setMore() {
        if (!this.moreEl) return;

        this.onMore = this.onMoreClick.bind(this);
        this.moreEl.addEventListener('click', this.onMore);
    }

    onMoreClick(event) {
        const textCol = this.root.querySelector('.text-image__text-col');
        const imageCol = this.root.querySelector('.text-image__image-col');

        if (textCol) {
            textCol.classList.add('-expand');
        }
        if (imageCol) {
            imageCol.classList.add('-expand');
        }

        if (this.moreColEl) this.moreColEl.style.display = 'none';
    }
}

function initTextImage() {
    const sections = document.querySelectorAll('[data-text-image]');
    if (!sections) return;

    sections.forEach(item => new TextImage(item));
}

if (
    document.readyState === 'complete' ||
    (document.readyState !== 'loading' && !document.documentElement.doScroll)
) {
    initTextImage();
} else {
    document.addEventListener('DOMContentLoaded', initTextImage);
}
